<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <div class="card mb-4 mt-4">
          <div class="card-header">
            <i class="fas fa-table me-1"></i>
            Jadwal
          </div>
          <div class="card-body"></div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "FormPage",
};
</script>