<template>
  <div id="layoutSidenav_nav">
    <nav class="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion">
      <div class="sb-sidenav-menu">
        <div class="nav">
          <div class="sb-sidenav-menu-heading">Menu</div>
          <!-- <router-link
            class="nav-link"
            to="/dashboard"
            :class="{
              ' active': link == 'dashboard',
            }"
          >
            <div class="sb-nav-link-icon">
              <i class="fas fa-tachometer-alt"></i>
            </div>
            Dashboard
          </router-link> -->
          <router-link
            class="nav-link"
            to="/jadwal"
            :class="{
              ' active': link == 'jadwal',
            }"
          >
            <div class="sb-nav-link-icon">
              <i class="fas fa-calendar"></i>
            </div>
            Jadwal
          </router-link>
          <a
            class="nav-link collapsed"
            href="javascript:;"
            data-bs-toggle="collapse"
            data-bs-target="#pagesCollapseMaster"
            aria-expanded="true"
            aria-controls="pagesCollapseMaster"
          >
            <div class="sb-nav-link-icon">
              <i class="fas fa-database"></i>
            </div>
            Master
            <div class="sb-sidenav-collapse-arrow">
              <i class="fas fa-angle-down"></i>
            </div>
          </a>
          <div
            class="collapse"
            :class="{ show: isMasterMenuOpen }"
            id="pagesCollapseMaster"
            aria-labelledby="headingOne"
            data-bs-parent="#sidenavAccordionPages"
          >
            <nav class="sb-sidenav-menu-nested nav">
              <router-link
                class="nav-link"
                to="/dosen"
                :class="{
                  ' active': link == 'dosen',
                }"
              >
                Dosen
              </router-link>
              <router-link
                class="nav-link"
                to="/ruang"
                :class="{
                  ' active': link == 'ruang',
                }"
              >
                Ruang
              </router-link>
              <router-link
                class="nav-link"
                to="/kelas"
                :class="{
                  ' active': link == 'kelas',
                }"
              >
                Kelas
              </router-link>
              <router-link
                class="nav-link"
                to="/mata-kuliah"
                :class="{
                  ' active': link == 'mata-kuliah',
                }"
              >
                Mata Kuliah
              </router-link>
            </nav>
          </div>
        </div>
      </div>
      <div class="sb-sidenav-footer">
        <div class="small">Develop by :</div>
        march.ikhsan@gmail.com
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: "SideBar",
  data() {
    return {
      link: "dashboard",
      isMasterMenuOpen: false,
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newValue) {
        const parts = newValue.split("/");
        const link = parts[1];
        this.link = link;
        this.isMasterMenuOpen =
          this.link === "dosen" ||
          this.link === "ruang" ||
          this.link === "kelas" ||
          this.link === "mata-kuliah";
      },
    },
  },
};
</script>

