var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"layoutSidenav_nav"}},[_c('nav',{staticClass:"sb-sidenav accordion sb-sidenav-light",attrs:{"id":"sidenavAccordion"}},[_c('div',{staticClass:"sb-sidenav-menu"},[_c('div',{staticClass:"nav"},[_c('div',{staticClass:"sb-sidenav-menu-heading"},[_vm._v("Menu")]),_c('router-link',{staticClass:"nav-link",class:{
            ' active': _vm.link == 'jadwal',
          },attrs:{"to":"/jadwal"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('i',{staticClass:"fas fa-calendar"})]),_vm._v(" Jadwal ")]),_vm._m(0),_c('div',{staticClass:"collapse",class:{ show: _vm.isMasterMenuOpen },attrs:{"id":"pagesCollapseMaster","aria-labelledby":"headingOne","data-bs-parent":"#sidenavAccordionPages"}},[_c('nav',{staticClass:"sb-sidenav-menu-nested nav"},[_c('router-link',{staticClass:"nav-link",class:{
                ' active': _vm.link == 'dosen',
              },attrs:{"to":"/dosen"}},[_vm._v(" Dosen ")]),_c('router-link',{staticClass:"nav-link",class:{
                ' active': _vm.link == 'ruang',
              },attrs:{"to":"/ruang"}},[_vm._v(" Ruang ")]),_c('router-link',{staticClass:"nav-link",class:{
                ' active': _vm.link == 'kelas',
              },attrs:{"to":"/kelas"}},[_vm._v(" Kelas ")]),_c('router-link',{staticClass:"nav-link",class:{
                ' active': _vm.link == 'mata-kuliah',
              },attrs:{"to":"/mata-kuliah"}},[_vm._v(" Mata Kuliah ")])],1)])],1)]),_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link collapsed",attrs:{"href":"javascript:;","data-bs-toggle":"collapse","data-bs-target":"#pagesCollapseMaster","aria-expanded":"true","aria-controls":"pagesCollapseMaster"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('i',{staticClass:"fas fa-database"})]),_vm._v(" Master "),_c('div',{staticClass:"sb-sidenav-collapse-arrow"},[_c('i',{staticClass:"fas fa-angle-down"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sb-sidenav-footer"},[_c('div',{staticClass:"small"},[_vm._v("Develop by :")]),_vm._v(" march.ikhsan@gmail.com ")])
}]

export { render, staticRenderFns }