<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <div class="card mb-4 mt-4">
          <div class="card-header">
            <i class="fas fa-table me-1"></i>
            Dosen
          </div>
          <div class="card-body">
            <form @submit.prevent="postData" enctype="multipart/form-data">
              <div class="row" v-if="openForm">
                <div class="col-md-3 mb-2">
                  <input type="hidden" v-model="form.id_dosen" />
                  <input
                    type="text"
                    v-model="form.nidn_nik"
                    class="form-control form-control-sm"
                    placeholder="NIDN/NIK"
                    :class="{
                      ' is-invalid': formValidate.nidn_nik,
                    }"
                  />
                </div>
                <div class="col-md-3 mb-2">
                  <input
                    type="text"
                    v-model="form.nama_dosen"
                    class="form-control form-control-sm"
                    placeholder="Nama"
                    :class="{
                      ' is-invalid': formValidate.nama_dosen,
                    }"
                  />
                </div>
                <div class="col-md-3 mb-2">
                  <input
                    type="text"
                    v-model="form.nomor_hp"
                    class="form-control form-control-sm"
                    placeholder="Nomor HP/WA"
                    :class="{
                      ' is-invalid': formValidate.nomor_hp,
                    }"
                  />
                </div>
                <div class="col-md-3 mb-2">
                  <div class="d-grid gap-2">
                    <button
                      class="btn btn-sm btn-primary"
                      type="button"
                      @click="postData()"
                      :disabled="this.loading"
                    >
                      <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :lineNumbers="true"
              :search-options="{
                enabled: true,
                placeholder: 'Cari',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 9,
                perPageDropdownEnabled: false,
                position: 'bottom',
                nextLabel: '',
                prevLabel: '',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: 'All',
              }"
            >
              <div slot="emptystate" class="text-center">
                {{ tableInfo }}
              </div>
              <div slot="table-actions">
                <div style="display: flex">
                  <button
                    type="button"
                    v-on:click="fetchData()"
                    class="btn btn-sm btn-light"
                    style="margin-right: 5px"
                  >
                    <i class="fa fa-sync" :class="{ 'fa-spin': loading }"></i>
                  </button>
                  <a
                    href="javascript:;"
                    @click="toggleForm()"
                    class="btn btn-sm btn-primary"
                    :class="{ 'btn-warning': openForm }"
                    style="margin-right: 5px"
                  >
                    <i
                      class="fa fa-plus d-md-none"
                      :class="{ 'fa-times': openForm }"
                    ></i>
                    <span class="d-none d-md-block">{{
                      openForm ? "Batal" : "Tambah Dosen"
                    }}</span>
                  </a>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <div>
                    <button
                      class="btn btn-sm btn-warning"
                      style="margin-right: 5px"
                      v-on:click="updateData(props.row.id_dosen)"
                    >
                      <i class="fa fa-pencil-alt"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      v-on:click="confirmDelete(props.row.id_dosen)"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "IndexPage",
  data() {
    return {
      columns: [
        {
          label: "NIDN/NIK",
          field: "nidn_nik",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Nama",
          field: "nama_dosen",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Nomor HP/WA",
          field: "nomor_hp",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      form: {
        id_dosen: "",
        nidn_nik: "",
        nomor_hp: "",
      },
      formValidate: [],
      link: "/api/dosen/create",
      tableInfo: "Tidak ada data yang dicari",
      loading: false,
      openForm: false,
    };
  },
  mounted() {
    this.fetchData();
    // this.loading = false;
  },
  methods: {
    fetchData() {
      this.rows = [];
      this.tableInfo = "Memperbarui data";
      this.loading = true;
      axios
        .get("/api/dosen/")
        .then((response) => {
          this.rows = response.data.data;
          this.tableInfo = "Tidak ada data yang dicari";
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/api/dosen/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    postData() {
      this.loading = true;

      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.fetchData();
                this.resetForm();
                this.loading = false;
                this.openForm = false;
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    updateData(id) {
      try {
        axios
          .get("/api/dosen/detail/" + id)
          .then((response) => {
            if (response.status === 200) {
              this.form.id_dosen = response.data.data.id_dosen;
              this.form.nama_dosen = response.data.data.nama_dosen;
              this.form.nidn_nik = response.data.data.nidn_nik;
              this.form.nomor_hp = response.data.data.nomor_hp;
              this.openForm = true;
              this.link = "/api/dosen/update";
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    toggleForm() {
      this.resetForm();
      this.link = "/api/dosen/create";
      this.openForm = !this.openForm;
    },
    resetForm() {
      this.form.id_dosen = "";
      this.form.nama_dosen = "";
      this.form.nidn_nik = "";
      this.form.nomor_hp = "";
      this.loading = false;
      this.formValidate = [];
    },
  },
};
</script>