<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <div class="card mb-4 mt-4">
          <div class="card-header">
            <i class="fas fa-table me-1"></i>
            Mata Kuliah
          </div>
          <div class="card-body">
            <form @submit.prevent="postData" enctype="multipart/form-data">
              <div class="row" v-if="openForm">
                <div class="col-md-2 mb-2">
                  <input type="hidden" v-model="form.id_mk" />
                  <select
                    v-model="form.semester"
                    class="form-control form-control-sm"
                    :class="{
                      ' is-invalid': formValidate.semester,
                    }"
                  >
                    <option value="">Pilih Semester</option>
                    <option value="1">I</option>
                    <option value="2">II</option>
                    <option value="3">III</option>
                    <option value="4">IV</option>
                    <option value="5">V</option>
                    <option value="6">VI</option>
                    <option value="7">VII</option>
                    <option value="8">VIII</option>
                  </select>
                </div>
                <div class="col-md-3 mb-2">
                  <input
                    type="text"
                    v-model="form.kode_mk"
                    class="form-control form-control-sm"
                    placeholder="Kode MK"
                    :class="{
                      ' is-invalid': formValidate.kode_mk,
                    }"
                  />
                </div>
                <div class="col-md-3 mb-2">
                  <input
                    type="text"
                    v-model="form.nama_mk"
                    class="form-control form-control-sm"
                    placeholder="Nama MK"
                    :class="{
                      ' is-invalid': formValidate.nama_mk,
                    }"
                  />
                </div>
                <div class="col-md-2 mb-2">
                  <input
                    type="number"
                    v-model="form.sks"
                    class="form-control form-control-sm"
                    placeholder="SKS"
                    :class="{
                      ' is-invalid': formValidate.sks,
                    }"
                  />
                </div>
                <div class="col-md-2 mb-2">
                  <div class="d-grid gap-2">
                    <button
                      class="btn btn-sm btn-primary"
                      type="button"
                      @click="postData()"
                      :disabled="this.loading"
                    >
                      <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :lineNumbers="true"
              :search-options="{
                enabled: true,
                placeholder: 'Cari',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 9,
                perPageDropdownEnabled: false,
                position: 'bottom',
                nextLabel: '',
                prevLabel: '',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: 'All',
              }"
            >
              <div slot="emptystate" class="text-center">
                {{ tableInfo }}
              </div>
              <div slot="table-actions">
                <div style="display: flex">
                  <button
                    type="button"
                    v-on:click="fetchData()"
                    class="btn btn-sm btn-light"
                    style="margin-right: 5px"
                  >
                    <i class="fa fa-sync" :class="{ 'fa-spin': loading }"></i>
                  </button>
                  <a
                    href="javascript:;"
                    @click="toggleForm()"
                    class="btn btn-sm btn-primary"
                    :class="{ 'btn-warning': openForm }"
                    style="margin-right: 5px"
                  >
                    <i
                      class="fa fa-plus d-md-none"
                      :class="{ 'fa-times': openForm }"
                    ></i>
                    <span class="d-none d-md-block">{{
                      openForm ? "Batal" : "Tambah MK"
                    }}</span>
                  </a>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'smt'">{{
                  semester(props.row.semester)
                }}</span>
                <span v-if="props.column.field == 'action'">
                  <div>
                    <button
                      class="btn btn-sm btn-warning"
                      style="margin-right: 5px"
                      v-on:click="updateData(props.row.id_mk)"
                    >
                      <i class="fa fa-pencil-alt"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      v-on:click="confirmDelete(props.row.id_mk)"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "IndexPage",
  data() {
    return {
      columns: [
        {
          label: "Semester",
          field: "smt",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Kode MK",
          field: "kode_mk",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Nama MK",
          field: "nama_mk",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "SKS",
          field: "sks",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      form: {
        id_mk: "",
        semester: "",
        kode_mk: "",
        nama_mk: "",
        sks: "",
      },
      formValidate: [],
      link: "/api/matakuliah/create",
      tableInfo: "Tidak ada data yang dicari",
      loading: false,
      openForm: false,
    };
  },
  mounted() {
    this.fetchData();
    // this.loading = false;
  },
  methods: {
    fetchData() {
      this.rows = [];
      this.tableInfo = "Memperbarui data";
      this.loading = true;
      axios
        .get("/api/matakuliah/")
        .then((response) => {
          this.rows = response.data.data;
          this.tableInfo = "Tidak ada data yang dicari";
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/api/matakuliah/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    postData() {
      this.loading = true;

      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.fetchData();
                this.resetForm();
                this.loading = false;
                this.openForm = false;
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    updateData(id) {
      try {
        axios
          .get("/api/matakuliah/detail/" + id)
          .then((response) => {
            if (response.status === 200) {
              for (let key in this.form) {
                this.form[key] = response.data.data[key];
              }
              this.openForm = true;
              this.link = "/api/matakuliah/update";
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    toggleForm() {
      this.resetForm();
      this.link = "/api/matakuliah/create";
      this.openForm = !this.openForm;
    },
    resetForm() {
      for (let key in this.form) {
        this.form[key] = "";
      }
      this.loading = false;
      this.formValidate = [];
    },
    semester(param) {
      switch (param) {
        case "1":
          return "I";
        case "2":
          return "II";
        case "3":
          return "III";
        case "4":
          return "IV";
        case "5":
          return "V";
        case "6":
          return "VI";
        case "7":
          return "VII";
        case "8":
          return "VIII";
        default:
          return param;
      }
    },
  },
};
</script>