<template>
  <div id="layoutAuthentication">
    <div id="layoutAuthentication_content">
      <main class="p-3">
        <button @click="goPrint()" class="btn btn-float-bottom btn-info">
          <i class="fa fa-print"></i>
        </button>
        <button @click="goBack()" class="btn btn-float-top btn-primary">
          <i class="fa fa-arrow-left"></i>
        </button>
        <table class="table table-bordered media-print">
          <thead>
            <tr>
              <th class="text-center">Hari</th>
              <th class="text-center">Jam</th>
              <th class="text-center">Kode MK</th>
              <th class="text-center">Mata Kuliah</th>
              <th class="text-center">SKS</th>
              <th class="text-center">PS/SMT</th>
              <th class="text-center">Ruang</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="rows.length === 0">
              <td colspan="8" class="text-center">Memuat Data</td>
            </tr>
            <tr v-for="jadwal in rows" :key="jadwal.id_jadwal">
              <td class="text-center">{{ formatHari(jadwal.hari) }}</td>
              <td class="text-center">
                {{ jadwal.jam_mulai }} - {{ jadwal.jam_selesai }}
              </td>
              <td class="text-center">{{ jadwal.kode_mk }}</td>
              <td class="text-center">{{ jadwal.nama_mk }}</td>
              <td class="text-center">{{ jadwal.sks }}</td>
              <td class="text-center">{{ jadwal.kode_kelas }}</td>
              <td class="text-center">{{ jadwal.nama_ruang }}</td>
            </tr>
          </tbody>
        </table>
      </main>
    </div>
  </div>
</template>
<style scoped>
.btn-float-bottom {
  width: 50px;
  height: 50px;
  line-height: 25px;
  text-align: center;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  position: fixed;
  right: 30px;
  bottom: 30px;
}

.btn-float-top {
  width: 50px;
  height: 50px;
  line-height: 25px;
  text-align: center;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  position: fixed;
  right: 30px;
  bottom: 90px;
}

@media print {
  .btn {
    display: none;
  }
  .table {
    width: 100%;
  }
}
</style>
<script>
import axios from "axios";

export default {
  name: "PrintPage",
  data() {
    return {
      kode_periode: "",
      rows: [],
      form: {
        id_jadwal: "",
        hari: "",
        kode_ruang: "",
        kode_kelas: "",
        kode_mk: "",
      },
      opsiRuang: [],
      opsiMatkul: [],
      opsiKelas: [],
      formValidate: [],
      link: "/api/jadwal/create",
      tableInfo: "Tidak ada data yang dicari",
      loading: false,
      openForm: false,
      message: "",
    };
  },
  mounted() {
    const id = this.$route.params.id;
    this.kode_periode = id;
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get("/api/jadwal/" + this.kode_periode)
        .then((response) => {
          this.rows = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    goPrint() {
      window.print();
    },
    formatHari(param) {
      switch (param) {
        case "1":
          return "Senin";
        case "2":
          return "Selasa";
        case "3":
          return "Rabu";
        case "4":
          return "Kamis";
        case "5":
          return "Jumat";
        default:
          return param;
      }
    },
    printTable() {
      // Memanggil fungsi window.print() untuk mencetak
      window.print();
    },
  },
};
</script>