import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Dashboard from "../pages/Dashboard.vue";
import Login from "../pages/Login.vue";
import Register from "../pages/Register.vue";
import Cetak from "../pages/Cetak.vue";
import Jadwal from "../pages/jadwal/Index.vue";
import NewPeriode from "../pages/jadwal/New.vue";
import ProsesJadwal from "../pages/jadwal/Form.vue";

import Dosen from "../pages/dosen/Index.vue";
import Ruang from "../pages/ruang/Index.vue";
import Kelas from "../pages/kelas/Index.vue";
import MataKuliah from "../pages/mata-kuliah/Index.vue";

const routes = [
  {
    path: "/",
    redirect: "/jadwal",
  },
  { path: "*", redirect: "/jadwal/" },
  { path: "/dashboard/", component: Dashboard },
  { path: "/cetak/:id", component: Cetak },
  { path: "/login/", component: Login },
  { path: "/registrasi/", component: Register },
  { path: "/jadwal/", component: Jadwal },
  { path: "/jadwal/periode-baru", component: NewPeriode },
  { path: "/jadwal/proses/:id", component: ProsesJadwal },
  { path: "/dosen/", component: Dosen },
  { path: "/ruang/", component: Ruang },
  { path: "/kelas/", component: Kelas },
  { path: "/mata-kuliah/", component: MataKuliah },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
