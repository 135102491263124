<template>
  <nav class="sb-topnav navbar navbar-expand navbar-dark bg-primary">
    <router-link class="navbar-brand ps-3" to="/jadwal">
      Jadwal Adhi Guna
    </router-link>
    <button
      class="btn btn-link btn-sm order-1 order-lg-0"
      id="sidebarToggle"
      @click="toggleSidebar"
    >
      <i class="fas fa-bars"></i>
    </button>
    <ul class="navbar-nav ms-auto me-0 me-md-3 my-2 my-md-0">
      <!-- <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          id="navbarDropdown"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          ><i class="fas fa-user fa-fw"></i
        ></a>
        <ul
          class="dropdown-menu dropdown-menu-end"
          aria-labelledby="navbarDropdown"
        >
          <li><a class="dropdown-item" href="javascript:;">Settings</a></li>
          <li><a class="dropdown-item" href="javascript:;">Activity Log</a></li>
          <li><hr class="dropdown-divider" /></li>
          <li>
            <router-link class="dropdown-item" to="/login">
              Logout
            </router-link>
          </li>
        </ul>
      </li> -->
    </ul>
  </nav>
</template>
<script>
export default {
  name: "NavBar",
  methods: {
    toggleSidebar() {
      document.body.classList.toggle("sb-sidenav-toggled");
      localStorage.setItem(
        "sb|sidebar-toggle",
        document.body.classList.contains("sb-sidenav-toggled")
      );
    },
  },
};
</script>