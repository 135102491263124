<template>
  <div class="my-component">
    <main>
      <div class="container-fluid px-4">
        <div class="card mb-4 mt-4">
          <div class="card-header">
            <i class="fas fa-table me-1"></i>
            Jadwal Periode {{ kode_periode }}
          </div>
          <div class="card-body">
            <form @submit.prevent="saveData" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-2 mb-2">
                  <select
                    v-model="form.hari"
                    class="form-control form-control-sm"
                    placeholder="Hari"
                    :class="{
                      ' is-invalid': formValidate.hari,
                    }"
                  >
                    <option value="">Pilih Hari</option>
                    <option value="1">Senin</option>
                    <option value="2">Selasa</option>
                    <option value="3">Rabu</option>
                    <option value="4">Kamis</option>
                    <option value="5">Jum'at</option>
                  </select>
                  <div
                    class="text-danger small"
                    v-html="formValidate.hari"
                  ></div>
                </div>
                <div class="col-md-4 mb-2">
                  <select
                    type="text"
                    v-model="form.kode_mk"
                    class="form-control form-control-sm"
                    placeholder="Ruang"
                    :class="{
                      ' is-invalid': formValidate.kode_mk,
                    }"
                  >
                    <option value="">Pilih Mata Kuliah</option>
                    <option
                      v-for="mk in opsiMatkul"
                      :key="mk.kode_mk"
                      :value="mk.kode_mk"
                    >
                      {{ mk.kode_mk }} - {{ mk.nama_mk }}
                    </option>
                  </select>
                  <div
                    class="text-danger small"
                    v-html="formValidate.kode_mk"
                  ></div>
                </div>
                <div class="col-md-2 mb-2">
                  <select
                    type="text"
                    v-model="form.kode_kelas"
                    class="form-control form-control-sm"
                    placeholder="Ruang"
                    :class="{
                      ' is-invalid': formValidate.kode_kelas,
                    }"
                  >
                    <option value="">Pilih Kelas</option>
                    <option
                      v-for="mk in opsiKelas"
                      :key="mk.kode_kelas"
                      :value="mk.kode_kelas"
                    >
                      {{ mk.kode_kelas }}
                    </option>
                  </select>
                  <div
                    class="text-danger small"
                    v-html="formValidate.kode_kelas"
                  ></div>
                </div>
                <div class="col-md-2 mb-2">
                  <select
                    type="text"
                    v-model="form.kode_ruang"
                    class="form-control form-control-sm"
                    placeholder="Ruang"
                    :class="{
                      ' is-invalid': formValidate.kode_ruang,
                    }"
                  >
                    <option value="">Pilih Ruang</option>
                    <option
                      v-for="ruang in opsiRuang"
                      :key="ruang.kode_ruang"
                      :value="ruang.kode_ruang"
                    >
                      {{ ruang.nama_ruang }}
                    </option>
                  </select>
                  <div
                    class="text-danger small"
                    v-html="formValidate.kode_ruang"
                  ></div>
                </div>
                <div class="col-md-1 mb-2">
                  <div class="d-grid">
                    <button
                      class="btn btn-sm btn-primary"
                      type="button"
                      @click="saveData()"
                      :disabled="this.loading"
                    >
                      <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                      Proses
                    </button>
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="d-grid">
                    <button
                      class="btn btn-sm btn-danger"
                      type="button"
                      @click="resetForm()"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                {{ message }}
              </div>
            </form>
            <div class="table-responsive">
              <table class="table table-bordered media-print">
                <thead>
                  <tr>
                    <th class="text-center align-middle">Hari</th>
                    <th class="text-center align-middle">Jam</th>
                    <th class="text-center align-middle">Kode MK</th>
                    <th class="text-center align-middle">Mata Kuliah</th>
                    <th class="text-center align-middle">SKS</th>
                    <th class="text-center align-middle">PS/SMT</th>
                    <th class="text-center align-middle">Ruang</th>
                    <th class="text-center hide-on-print">
                      <router-link
                        class="btn btn-sm btn-success"
                        :to="'/cetak/' + kode_periode"
                      >
                        Cetak
                      </router-link>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="rows.length === 0">
                    <td colspan="8" class="text-center">Memuat Data</td>
                  </tr>
                  <tr v-for="jadwal in rows" :key="jadwal.id_jadwal">
                    <td class="text-center">{{ formatHari(jadwal.hari) }}</td>
                    <td class="text-center">
                      {{ jadwal.jam_mulai }} - {{ jadwal.jam_selesai }}
                    </td>
                    <td class="text-center">{{ jadwal.kode_mk }}</td>
                    <td class="text-center">{{ jadwal.nama_mk }}</td>
                    <td class="text-center">{{ jadwal.sks }}</td>
                    <td class="text-center">{{ jadwal.kode_kelas }}</td>
                    <td class="text-center">{{ jadwal.nama_ruang }}</td>
                    <td class="text-center hide-on-print">
                      <button
                        class="btn btn-sm btn-warning"
                        style="margin: 2px 2px 2px 2px"
                        v-on:click="updateData(jadwal.id_jadwal)"
                      >
                        <i class="fa fa-pencil-alt"></i>
                      </button>
                      <button
                        class="btn btn-sm btn-danger"
                        style="margin: 2px 2px 2px 2px"
                        v-on:click="forceDelete(jadwal.id_jadwal)"
                      >
                        <i class="fa fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<style scoped>
.table tr th,
.table tr td {
  /* border: 1px solid black !important; */
  padding: 4px;
  font-size: 12px;
  vertical-align: middle;
}
@media print {
  .media-print {
    display: block;
  }
  .hide-on-print {
    display: none;
  }
}
</style>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "FormPage",
  data() {
    return {
      kode_periode: "",
      rows: [],
      form: {
        id_jadwal: "",
        hari: "",
        kode_ruang: "",
        kode_kelas: "",
        kode_mk: "",
      },
      opsiRuang: [],
      opsiMatkul: [],
      opsiKelas: [],
      formValidate: [],
      link: "/api/jadwal/create",
      tableInfo: "Tidak ada data yang dicari",
      loading: false,
      openForm: false,
      message: "",
    };
  },
  mounted() {
    const id = this.$route.params.id;
    this.kode_periode = id;
    this.fetchData();
    this.getRuang();
    this.getMatkul();
    this.getKelas();
    // this.loading = false;
  },
  methods: {
    fetchData() {
      // this.rows = [];
      this.tableInfo = "Memperbarui data";
      // this.loading = true;
      axios
        .get("/api/jadwal/" + this.kode_periode)
        .then((response) => {
          this.rows = response.data.data;
          this.tableInfo = "Tidak ada data yang dicari";
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/api/jadwal/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    forceDelete(id) {
      try {
        axios
          .get("/api/jadwal/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              this.fetchData();
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    saveData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      formData.append("kode_periode", this.kode_periode);
      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            if (response.data.status) {
              this.fetchData();
              this.resetForm();
              this.loading = false;
              this.openForm = false;
            } else {
              Swal.fire({
                title: response.data.title,
                text: response.data.message,
                icon: response.data.icon,
                showCancelButton: false,
                showConfirmButton: false,
              });
              setTimeout(() => {
                Swal.close();
                this.loading = false;
              }, 700);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      // this.loading = true;

      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      formData.append("kode_periode", this.kode_periode);
      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.fetchData();
                this.resetForm();
                this.loading = false;
                this.openForm = false;
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    updateData(id) {
      try {
        axios
          .get("/api/jadwal/detail/" + id)
          .then((response) => {
            if (response.status === 200) {
              for (let key in this.form) {
                this.form[key] = response.data.data[key];
              }
              this.openForm = true;
              this.link = "/api/jadwal/update";
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    toggleForm() {
      this.resetForm();
      this.link = "/api/jadwal/create";
      this.openForm = !this.openForm;
    },
    resetForm() {
      for (let key in this.form) {
        this.form[key] = "";
      }
      this.loading = false;
      this.formValidate = [];
      this.link = "/api/jadwal/create";
    },
    getRuang() {
      axios
        .get("/api/ruang/")
        .then((response) => {
          this.opsiRuang = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getMatkul() {
      axios
        .get("/api/matakuliah/")
        .then((response) => {
          this.opsiMatkul = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKelas() {
      axios
        .get("/api/kelas/")
        .then((response) => {
          this.opsiKelas = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    formatHari(param) {
      switch (param) {
        case "1":
          return "Senin";
        case "2":
          return "Selasa";
        case "3":
          return "Rabu";
        case "4":
          return "Kamis";
        case "5":
          return "Jumat";
        default:
          return param;
      }
    },
    printTable() {
      // Memanggil fungsi window.print() untuk mencetak
      window.print();
    },
  },
};
</script>